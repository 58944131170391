<template>
  <main id="exam-result">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <b-breadcrumb :items="breadcrumb" class="m-0" />
        </div>
      </div>
    </div>

    <b-row>
      <b-col>
        <b-card v-if="diagnosis">
          <TitleAndDescriptionScreen
            title="Resultado do estudo de -"
            :customerName="diagnosis.pessoa.pessoa_nome"
            :diagnosis="diagnosis"
            description="Estes são os resultados e recomendações para o seu cliente:"
            v-if="diagnosis"
          />

          <GeneralProfile :diagnosis="diagnosis" />

          <FinanceIndicators :diagnosis="diagnosis" />

          <Priorities :diagnosis="diagnosis" />

          <b-row>
            <b-col>
              <b-card>
                <div style="width: 100%">
                  <b-card no-body style="margin: 0 -20px" v-if="diagnosis">
                    <b-tabs content-class="mt-6" fill v-model="currentTab">
                      <b-tab
                        no-body
                        title-item-class="tab-product"
                        :key="diagnosisProduct.diagnostico_produto_id"
                        v-for="diagnosisProduct in selectedDiagnosticProducts"
                      >
                        <template #title>
                          <div style="width: 100%">
                            <div class="div-icon-title">
                              <TabProduct2
                                :icon="
                                  diagnosisProduct.produto_diagnostico.icone
                                "
                                :color="
                                  diagnosisProduct.produto_diagnostico.cor
                                "
                                :name="diagnosisProduct.nome_produto"
                              />
                            </div>
                          </div>
                        </template>

                        <StepView
                          :diagnosis="diagnosis"
                          :data="diagnosisProduct"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>

                <div class="text-center">
                  <Button
                    :onClick="() => viewRecommendation()"
                    text="VISUALIZAR RECOMENDAÇÃO"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import { updatePhase, requestDiagnosis } from '@/services/requests/diagnosis';

import GeneralProfile from '@/components/DiagnosisComplete/common/GeneralProfile';
import FinanceIndicators from '@/components/DiagnosisComplete/common/FinanceIndicators';
import Priorities from '@/components/DiagnosisComplete/common/Priorities';
import StepView from '@/components/DiagnosisComplete/ExamResult/StepView';
import TitleAndDescriptionScreen from '@/components/DiagnosisComplete/common/TitleAndDescriptionScreen';
import TabProduct2 from '@/components/DiagnosisComplete/common/TabProduct2';
import Button from '@/components/DiagnosisComplete/common/Button';

export default {
  name: 'ExamResult',
  components: {
    GeneralProfile,
    FinanceIndicators,
    Priorities,
    StepView,
    TabProduct2,
    TitleAndDescriptionScreen,
    Button
  },
  data() {
    return {
      diagnosis: null,
      currentTab: 0
    };
  },
  methods: {
    loadDiagnosis() {
      requestDiagnosis(this.diagnosisId)
        .then((res) => {
          this.diagnosis = res.data.result.data;
        })
        .catch((error) => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },
    viewRecommendation() {
      if (!this.diagnosis) return;

      const diagnosisId = this.diagnosis.diagnostico_id;

      const data = {
        estagio_id: 3
      };

      if (!this.diagnosis.enviado_especialista) {
        data.enviado_especialista = confirm(
          'Deseja enviar para o especialista?'
        );
      }

      if (this.diagnosis.diagnostico_historico.estagio_id >= data.estagio_id) {
        this.$router.push({
          name: 'RevenueQuotation',
          params: { diagnosisId }
        });
        return;
      }

      updatePhase(diagnosisId, data)
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({
            name: 'RevenueQuotation',
            params: { diagnosisId }
          });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    diagnosisId() {
      return this.$route.params.diagnosisId;
    },
    breadcrumb() {
      const customerId = this.diagnosis
        ? this.diagnosis.pessoa.cliente.cliente_id
        : null;

      return [
        {
          text: 'Dashboards',
          href: '/'
        },
        {
          text: 'Gerar estudo completo',
          href: `/diagnosis/complete/${customerId}`
        },
        {
          text: 'Direcionamento do consultor',
          href: `/diagnosis/complete/consultant-direction/${this.diagnosisId}`
        },
        {
          text: 'Resultado do estudo',
          active: true
        }
      ];
    },
    selectedDiagnosticProducts() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_produtos.filter(
        (product) => product.selecionado
      );
    }
  },
  mounted() {
    this.loadDiagnosis();
  }
};
</script>

<style lang="scss" scoped>
.nav-fill .tab-product .nav-link {
  .div-icon-title {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 3rem;

    i:first-child {
      font-size: 1.3rem;
    }

    strong {
      font-size: 12px;
      margin-left: 5%;
    }
  }
}
.nav-fill .tab-product .nav-link:not(.active) {
  .div-icon-title {
    padding-left: 10px;
  }
}
</style>
