<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="'accordion-priorities'"
        class="text-left rounded-0"
        variant="secondary"
        style="font-size: 1.25rem"
      >
        <i class="bx bx-sort"></i>
        PRIORIDADES

        <i class="bx bxs-chevron-up when-closed float-right mt-1"></i>
        <i class="bx bxs-chevron-down when-opened float-right mt-1"></i>
      </b-button>
    </b-card-header>

    <b-card-body style="background-color: var(--background-light-grey)">
      <b-collapse id="accordion-priorities" role="tabpanel">
        <b-row>
          <b-col md="12" class="priorities-container">
            <div
              v-for="(priority, index) in priorities"
              :key="index"
              class="priorities-card"
            >
              <template>
                <div class="card-note">
                  <strong>Nota - {{ index + 1 }}</strong>
                </div>
                <div class="priorities-content">
                  <p>{{ priority.nome }}</p>
                </div>
                <div class="priorities-circle">
                  <strong>{{ priority.nota }}</strong>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Priorities',
  props: {
    diagnosis: {
      type: Object,
      required: true
    }
  },
  computed: {
    priorities() {
      return this.diagnosis.diagnostico_prioridades.filter(
        (priority) => priority.nota > 0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.priorities-container {
  display: flex;
  align-items: center;
  padding: 2rem;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.priorities-card {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  width: 30%;
  height: 6.5rem;
  background: var(--white);
  border-radius: 4px;
  margin-right: 1rem;

  .card-note {
    background: var(--blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    font-size: 15px;
    padding-left: 1rem;

    width: 100%;
    height: 50%;
    border-radius: 4px;
  }
}

.priorities-content {
  width: 100%;
  height: 50%;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  font-size: 11px;

  p {
    margin-bottom: -1px;
    color: var(--grey);
  }
}

.priorities-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: var(--white);

  top: 1.5rem;
  right: 1rem;

  strong {
    font-size: 23px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--grey);
  }
}

.priorities-card-featured {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 30%;
  height: 6.5rem;
  background: var(--red-dark);
  border-radius: 4px;
  padding: 1rem;
  margin-right: 1rem;
}

.priorities-content-featured {
  width: 65%;

  p {
    margin-bottom: -1px;
    color: var(--white);
  }

  strong {
    font-size: 14px;
    color: var(--red);
  }
}

.priorities-circle-featured {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: var(--light-grey-2);

  margin-left: 1rem;

  strong {
    font-size: 23px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--blue);
  }
}
</style>
