<template>
  <div>
    <IconLife v-if="icon === 'fas fa-heartbeat icon-heart'" :color="color" />

    <IconHealth v-if="icon === 'fas fa-medkit icon-health'" :color="color" />

    <IconDream v-if="icon === 'bx bx-cloud icon-cloud'" :color="color" />

    <IconProtection
      v-if="icon === 'bx bx-check-shield icon-cloud'"
      :color="color"
    />

    <IconMoney v-if="icon === 'bx bx-credit-card icon-cloud'" :color="color" />

    <IconExchange v-if="icon === 'bx bx-dollar icon-cloud'" :color="color" />

    <IconRealState :color="color" v-if="icon === 'bx bx-home icon-cloud'" />

    <strong
      :style="{
        color: color
      }"
    >
      {{ name }}
    </strong>
  </div>
</template>

<script>
import IconLife from '@/assets/images/icons/products/IconLife.vue';
import IconHealth from '@/assets/images/icons/products/IconHealth.vue';
import IconDream from '@/assets/images/icons/products/IconDream.vue';
import IconProtection from '@/assets/images/icons/products/IconProtection.vue';
import IconMoney from '@/assets/images/icons/products/IconMoney.vue';
import IconExchange from '@/assets/images/icons/products/IconExchange.vue';
import IconRealState from '@/assets/images/icons/products/IconRealState.vue';

export default {
  props: {
    icon: String,
    color: String,
    name: String
  },
  components: {
    IconLife,
    IconHealth,
    IconDream,
    IconProtection,
    IconMoney,
    IconExchange,
    IconRealState
  }
};
</script>
