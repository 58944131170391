<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="'accordion-finance-indicators'"
        class="text-left rounded-0"
        variant="secondary"
        style="font-size: 1.25rem"
      >
        <i class="bx bxs-traffic"></i>
        INDICADORES FINANCEIROS

        <i class="bx bxs-chevron-up when-closed float-right mt-1"></i>
        <i class="bx bxs-chevron-down when-opened float-right mt-1"></i>
      </b-button>
    </b-card-header>

    <b-card-body
      style="background-color: var(--background-light-grey)"
      v-if="diagnosis"
    >
      <b-collapse id="accordion-finance-indicators" role="tabpanel">
        <b-row>
          <b-col
            md="12"
            id="indicator-finances-container"
            class="indicator-finances-container"
          >
            <div
              v-for="(indicator, index) in diagnosis.indicadores_financeiros"
              :key="index"
              class="indicator-finance-card"
              :style="{ border: `1px solid ${indicator.color}` }"
            >
              <div class="content-indicator">
                <p>{{ indicator.name }}</p>

                <div class="font-weight-bold">
                  <span v-if="indicator.type === 'money'">
                    R$ {{ indicator.value | decimal }}
                  </span>

                  <span v-if="indicator.type === 'money_percentage'">
                    R$ {{ indicator.value | decimal }} =
                    {{ indicator.percentage | decimal }}%
                  </span>

                  <span v-if="indicator.type === 'months'">
                    {{ indicator.value }} meses
                  </span>
                </div>
              </div>

              <div
                class="circle-indicator"
                v-b-tooltip.hover="
                  !indicator.tooltip
                    ? undefined
                    : {
                        title: indicator.tooltip,
                        html: true,
                        placement: 'bottom'
                      }
                "
                :style="{ backgroundColor: indicator.color }"
              ></div>
            </div>
          </b-col>
          <i
            @click="() => handleScrollxAxis('right')"
            class="fas fa-chevron-right style-arrow-right"
          />
          <i
            v-if="scrollsize"
            @click="() => handleScrollxAxis('left')"
            class="fas fa-chevron-left style-arrow-left"
          />
        </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'FinanceIndicators',
  props: {
    diagnosis: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      scrollsize: 0
    };
  },
  methods: {
    handleScrollxAxis(side) {
      if (this.scrollsize >= 0) {
        if (side === 'right') {
          (this.scrollsize += 320),
            document.getElementById('indicator-finances-container').scrollTo({
              left: this.scrollsize,
              behavior: 'smooth'
            });
        }

        if (side === 'left') {
          if (this.scrollsize - 320 < 0) return;

          (this.scrollsize -= 320),
            document.getElementById('indicator-finances-container').scrollTo({
              left: this.scrollsize,
              behavior: 'smooth'
            });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-finances-container {
  display: flex;
  align-items: center;

  overflow-x: auto;
  white-space: nowrap;

  padding: 1rem;
}

.indicator-finance-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 0 0 200px;
  height: 6rem;
  border-radius: 4px;
  padding: 1rem;
  margin-right: 1rem;
}

.content-indicator {
  width: 75%;

  p {
    margin-bottom: none;
    white-space: break-spaces;
  }
}

.circle-indicator {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.style-arrow-right,
.style-arrow-left {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 4rem;
  height: 4rem;
  background: rgb(204, 204, 204);
  opacity: 0.95;
  border-radius: 50%;

  top: 45%;
  right: 0.6rem;

  cursor: pointer;
}

.style-arrow-left {
  left: 0.6rem;
}
</style>
