<template>
  <b-row style="padding: 1rem">
    <b-col :class="item.tipo_grafico === 0 ? 'col-12' : 'col-12 col-xxl-6'">
      <h5 style="margin-top: 0.5rem">{{ item.titulo }}</h5>
      <p style="font-size: 12.5px; margin-top: 1rem">
        {{ item.descricao }}
      </p>

      <ContactDataTable
        :diagnosis="diagnosis"
        v-if="
          ([2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id) ||
            ['Seguro patrimonial'].includes(item.grupo) ||
            (item.id_campo === 14 &&
              diagnosisProduct.produto_diagnostico_id !== 3)) &&
          item.grupo !== 'Direcionamento ao especialista'
        "
      />

      <ContactDataHealthTable
        :diagnosis="diagnosis"
        v-if="item.grupo === 'Saúde'"
      />

      <ConsultantDataTable
        :item="item"
        v-if="
          [2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id) &&
          item.grupo !== 'Direcionamento ao especialista'
        "
      />

      <table class="table" v-if="item.exibir_tabela">
        <tr class="bg-secondary text-white">
          <th>ITENS</th>
          <th v-if="[2, 3].includes(item.id_campo)">
            Despesas com o prazo (em anos)
          </th>
          <th>
            <span v-if="item.tipo_tabela === 1">Necessidade</span>
            <span v-if="item.tipo_tabela === 2">Sugeridos</span>
          </th>
          <th v-if="item.tipo_tabela === 1">Valor atual</th>
          <th v-if="item.tipo_tabela === 1">Valor necessário</th>
          <th v-if="item.tipo_tabela === 1">Valor sugerido</th>
        </tr>
        <tr
          v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
          :key="tableItem.diagnostico_produto_item_tabela_item_id"
        >
          <td>
            {{ tableItem.itens }}
            <i
              v-b-tooltip.hover
              :title="tableItem.tooltip"
              class="bx bx-help-circle"
              style="font-size: 1.2rem"
              v-if="tableItem.tooltip"
            />
          </td>

          <td v-if="[2, 3].includes(item.id_campo) && !tableItem.despesa_prazo">
            -
          </td>
          <td v-else-if="[2, 3].includes(item.id_campo)">
            {{ tableItem.despesa_prazo }} anos
          </td>

          <td v-if="item.tipo_tabela === 1 && !tableItem.necessidades">-</td>
          <td v-else-if="item.tipo_tabela === 1">
            R$ {{ tableItem.necessidades | decimalWithoutCents }}
          </td>

          <td v-if="item.tipo_tabela === 1 && !tableItem.valor_atual">-</td>
          <td v-else-if="item.tipo_tabela === 1">
            R$ {{ tableItem.valor_atual | decimalWithoutCents }}
          </td>

          <td v-if="item.tipo_tabela === 1 && !tableItem.valor_necessario">
            -
          </td>
          <td v-else-if="item.tipo_tabela === 1">
            R$ {{ tableItem.valor_necessario | decimalWithoutCents }}
          </td>

          <td v-if="item.tipo_tabela === 1 && !tableItem.sugeridos">-</td>
          <td v-else-if="item.tipo_tabela === 1">
            R$ {{ tableItem.sugeridos | decimalWithoutCents }}
          </td>

          <td v-if="item.tipo_tabela === 2 && !tableItem.sugeridos">-</td>
          <td v-else-if="item.tipo_tabela === 2 && tableItem.tipo_coluna === 1">
            R$ {{ tableItem.sugeridos | decimalWithoutCents }}
          </td>
          <td v-else-if="item.tipo_tabela === 2">
            {{ tableItem.sugeridos }}
          </td>
        </tr>

        <tr
          v-if="
            item.tipo_tabela === 1 &&
            item.diagnostico_produto_item_tabela_itens.length > 1
          "
        >
          <td><strong>Total</strong></td>
          <td v-if="[2, 3].includes(item.id_campo)">
            {{ item.despesa_prazo }} anos
          </td>
          <td>
            <strong v-if="item.necessidades">
              R$ {{ item.necessidades | decimalWithoutCents }}
            </strong>
            <strong v-if="!item.necessidades"> - </strong>
          </td>
          <td>
            <strong v-if="item.valor_atual">
              R$ {{ item.valor_atual | decimalWithoutCents }}
            </strong>
            <strong v-if="!item.valor_atual"> - </strong>
          </td>
          <td>
            <strong v-if="item.valor_necessario">
              R$ {{ item.valor_necessario | decimalWithoutCents }}
            </strong>
            <strong v-if="!item.valor_necessario"> - </strong>
          </td>
          <td>
            <strong v-if="totalSugeridos">
              R$ {{ totalSugeridos | decimalWithoutCents }}
            </strong>
            <strong v-if="!totalSugeridos"> - </strong>
          </td>
        </tr>
      </table>
    </b-col>

    <StepItemGraph :diagnosis="diagnosis" :item="item" />

    <b-col cols="12" class="d-flex justify-content-end align-items-center mb-3">
      <b-button-group
        class="mt-2 mr-2"
        style="padding: 6px 0"
        @click="() => viewSpecialistComment()"
        v-if="
          item.diagnostico_comentario_especialista &&
          item.diagnostico_comentario_especialista.comentario
        "
      >
        <b-button
          style="
            background: var(--border-blue-light);
            border-color: var(--border-blue-light);
          "
        >
          <i class="fas fa-eye" style="font-size: 1rem"></i>
        </b-button>

        <b-button variant="primary"> Ver comentário do especialista </b-button>
      </b-button-group>

      <ButtonEditComment
        class="mt-2"
        icon="plus"
        :title="`${
          item.diagnostico_comentario && item.diagnostico_comentario.comentario
            ? 'EDITAR'
            : 'ADICIONAR'
        } COMENTÁRIO`"
        :openModalEditComment="() => openModalAddEditComment()"
      />
    </b-col>

    <ModalViewComment
      title="Comentário do especialista"
      :isOpen="isOpenModalViewComment"
      :diagnosisComment="item.diagnostico_comentario_especialista"
      @closeModal="closeModalViewComment()"
    />

    <ModalAddEditComment
      :isOpen="isOpenModalAddEditComment"
      :data="modalData"
      @onSaveComment="(diagnosisComment) => onSaveComment(diagnosisComment)"
      @closeModal="closeModalAddEditComment()"
    />
  </b-row>
</template>

<script>
import StepItemGraph from './StepViewItemGraph';
import ContactDataTable from '@/components/common/ContactDataTable';
import ContactDataHealthTable from '@/components/common/ContactDataHealthTable';
import ConsultantDataTable from '@/components/common/ConsultantDataTable';

import ButtonEditComment from '@/components/Buttons/ButtonEditComment';
import ModalAddEditComment from '@/components/common/Modals/ModalAddEditComment';
import ModalViewComment from '@/components/common/Modals/ModalViewComment';

export default {
  name: 'StepViewItem',
  props: {
    diagnosis: Object,
    item: Object,
    diagnosisProduct: Object
  },
  components: {
    StepItemGraph,
    ContactDataTable,
    ContactDataHealthTable,
    ConsultantDataTable,
    ButtonEditComment,
    ModalAddEditComment,
    ModalViewComment
  },
  data() {
    return {
      modalData: null,
      isOpenModalAddEditComment: false,
      isOpenModalViewComment: false
    };
  },
  methods: {
    openModalAddEditComment() {
      this.modalData = this.item.diagnostico_comentario || {
        diagnostico_comentario_id: null,
        diagnostico_produto_item_id: this.item.diagnostico_produto_item_id,
        comentario: null,
        download_url: null
      };

      this.isOpenModalAddEditComment = true;
    },

    onSaveComment(diagnosisComment) {
      this.$set(this.item, 'diagnostico_comentario', diagnosisComment);
      this.closeModalAddEditComment();
    },

    closeModalAddEditComment() {
      this.isOpenModalAddEditComment = false;
    },

    viewSpecialistComment() {
      this.isOpenModalViewComment = true;
    },

    closeModalViewComment() {
      this.isOpenModalViewComment = false;
    }
  },
  computed: {
    totalSugeridos() {
      if (this.item.tipo_tabela !== 1) return null;

      return this.item.diagnostico_produto_item_tabela_itens.reduce(
        (total, item) => total + item.sugeridos,
        0
      );
    }
  }
};
</script>
