<template>
  <div>
    <div class="accordion" role="tablist" style="margin-top: 1rem">
      <b-card no-body class="mb-1">
        <div v-for="(group, index) in groups" :key="`group-${index}-1`">
          <b-card-header header-tag="header" class="px-1 pt-1 pb-0" role="tab">
            <b-button
              :style="`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                text-transform: uppercase;
              `"
              block
              variant="secondary"
              v-b-toggle="`accordion-item-${index}`"
            >
              {{ group.name.toUpperCase() }}
              <span class="when-opened">
                <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
              </span>
              <span class="when-closed">
                <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
              </span>
            </b-button>
          </b-card-header>

          <b-collapse :id="`accordion-item-${index}`" role="tabpanel" visible>
            <b-card-body class="pt-0">
              <div
                v-for="(item, itemIndex) in group.items"
                :key="`item-${item.diagnostico_produto_item_id}`"
              >
                <div
                  class="bg-secondary"
                  :style="`
                    height: 2.5rem;
                    margin-left: -1rem;
                    margin-right: -1rem;
                    padding: 10px;
                  `"
                  v-if="
                    item.subgrupo &&
                    (itemIndex === 0 ||
                      group.items[itemIndex - 1].subgrupo !== item.subgrupo)
                  "
                >
                  <p style="color: var(--white)">
                    {{ item.subgrupo.toUpperCase() }}
                  </p>
                </div>

                <hr
                  style="padding: 1px"
                  class="bg-secondary"
                  v-else-if="itemIndex !== 0"
                />

                <StepItem
                  :diagnosis="diagnosis"
                  :item="item"
                  :diagnosisProduct="data"
                />
              </div>
            </b-card-body>
          </b-collapse>
        </div>
      </b-card>

      <div v-for="(group, index) in groups" :key="`group-${index}-2`">
        <div v-if="group.name === 'Direcionamento ao especialista'">
          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  background: var(--secondary);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-2
                variant="info"
                >POSIÇÃO PATRIMONIAL
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i
                    class="bx bx-chevron-down"
                    style="font-size: 1.5rem"
                  ></i> </span
              ></b-button>
            </b-card-header>

            <b-collapse id="accordion-2" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic1 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    id="chart_div"
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic2 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <EmergencyReserve :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <LiquidInvestments :diagnosis="diagnosis" />

                <InvestmentProjection :diagnosis="diagnosis" />
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  background: var(--secondary);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-3
                variant="info"
              >
                LIBERDADE FINANCEIRA
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i
                    class="bx bx-chevron-down"
                    style="font-size: 1.5rem"
                  ></i> </span
              ></b-button>
            </b-card-header>

            <b-collapse id="accordion-3" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Pension :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <FinancialFreedom :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <CurrentAssetsVsFinancialFreedom :diagnosis="diagnosis" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script>
import StepItem from './StepViewItem';
import Graphic1 from '@/components/common/Graphics/Graphic1';
import Graphic2 from '@/components/common/Graphics/Graphic2';
import EmergencyReserve from '@/components/common/Graphics/EmergencyReserve';
import Pension from '@/components/common/Graphics/Pension';
import LiquidInvestments from '@/components/common/Graphics/LiquidInvestments';
import InvestmentProjection from '@/components/common/Graphics/InvestmentProjection';
import FinancialFreedom from '@/components/common/Graphics/FinancialFreedom';
import CurrentAssetsVsFinancialFreedom from '@/components/common/Graphics/CurrentAssetsVsFinancialFreedom';

export default {
  name: 'StepView',
  components: {
    StepItem,
    Graphic1,
    Graphic2,
    EmergencyReserve,
    Pension,
    LiquidInvestments,
    InvestmentProjection,
    FinancialFreedom,
    CurrentAssetsVsFinancialFreedom
  },
  props: {
    diagnosis: Object,
    data: Object
  },

  computed: {
    groups() {
      const groups = {};

      this.data.diagnostico_produto_itens
        .filter((item) => item.fazer_cotacao)
        .forEach((item) => {
          if (!groups[item.grupo]) {
            groups[item.grupo] = {
              name: item.grupo,
              items: []
            };
          }

          groups[item.grupo].items.push(item);
        });

      return Object.values(groups);
    }
  }
};
</script>
