<template>
  <b-col
    :class="
      item.tipo_grafico === 3 ||
      item.id_campo === 14 ||
      item.titulo === 'Consórcio' ||
      item.titulo === 'Proteção do Patrimônio'
        ? 'col-12'
        : 'col-12 col-xxl-6'
    "
    class="graph-column"
  >
    <div v-if="item.tipo_grafico === 1">
      <highcharts
        responsive
        :options="columnChart"
        v-if="columnChart.series.length > 0"
      />
      <div v-else>
        O cliente não possui um seguro do tipo
        <strong>
          {{ item.seguro ? item.seguro.nome : '' }}
        </strong>
      </div>
    </div>

    <b-table-simple v-if="item.tipo_grafico === 2">
      <b-tbody>
        <tr class="bg-secondary text-white">
          <th>PLANO ATUAL</th>
          <th></th>
        </tr>
        <b-tr
          v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
          :key="tableItem.diagnostico_produto_item_tabela_item_id"
        >
          <b-th>{{ tableItem.coluna_titulo }}</b-th>
          <b-td>{{ tableItem.coluna_valor }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <table class="table" v-if="[5, 6].includes(item.tipo_tabela)">
      <tr class="bg-secondary text-white">
        <th align="center">
          <div style="height: 2rem; display: flex; align-items: center">
            ITENS
          </div>
        </th>
        <th>
          <div style="height: 2rem; display: flex; align-items: center">
            VALOR ATUAL
          </div>
        </th>
      </tr>
      <tr
        v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
        :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
      >
        <td>{{ tableItem.itens }}</td>
        <td v-if="tableItem.tipo_coluna === 1">
          R$ {{ tableItem.valor_cliente | decimal }}
        </td>
        <td v-else>{{ tableItem.valor_cliente }}</td>
      </tr>
    </table>
  </b-col>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { cloneObject } from '@/helpers';

import {
  uploadProductItemOption,
  updateDiagnosisProductItemOpcoes
} from '@/services/requests/diagnosis';

export default {
  name: 'StepViewItemGraph',
  components: {
    highcharts: Chart
  },

  props: {
    diagnosis: Object,
    item: Object
  },

  data() {
    return {
      currentOptionIndex: 0,
      columnChart: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Capital segurado'
        },
        xAxis: {
          labels: {
            enabled: false
          }
        },
        yAxis: {
          min: 0
        },
        plotLines: [
          {
            value: 0,
            width: 10,
            color: '#808080'
          }
        ],
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(this.y);
              }
            },
            enableMouseTracking: true
          }
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 600
              },
              chartOptions: {
                legend: {
                  enabled: true
                }
              }
            }
          ]
        }
      },
      editMode: false
    };
  },

  mounted() {
    this.renderDataGraph();
  },

  methods: {
    series(necessidades, valor_atual, valor_necessario, sugeridos) {
      return [
        {
          name: 'Necessidades',
          data: [necessidades],
          color: 'var(--color-graph-1)',
          type: 'column'
        },
        {
          name: 'Valor atual',
          data: [valor_atual],
          color: 'var(--color-graph-2)',
          type: 'column'
        },
        {
          name: 'Valor necessário',
          data: [valor_necessario],
          color: 'var(--color-graph-3)',
          type: 'column'
        },
        {
          name: 'Valor Sugerido',
          data: [sugeridos],
          color: 'var(--color-graph-4)',
          type: 'column'
        }
      ];
    },

    async handleFileUpload(option, optionIndex) {
      if (!option.file) return;

      if (!option.diagnostico_produto_item_opcao_id) {
        const updatedItem = await this.saveQuotationRequest();
        const updatedOption =
          updatedItem.diagnostico_produto_item_opcoes[optionIndex];

        option.diagnostico_produto_item_opcao_id =
          updatedOption.diagnostico_produto_item_opcao_id;
      }

      const formData = new FormData();
      formData.append('file', option.file);

      const optionId = option.diagnostico_produto_item_opcao_id;

      uploadProductItemOption(optionId, option.file)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleAddOption() {
      const option = {
        diagnostico_produto_item_opcao_id: null,
        seguradora: null,
        capital_segurado: 0,
        solucao: null,
        periodo_cobertura: null,
        periodo_pagamento: null,
        premio_mensal: 0,
        premio_anual: 0
      };

      this.item.diagnostico_produto_item_opcoes.push(option);
      this.currentOptionIndex =
        this.item.diagnostico_produto_item_opcoes.length - 1;
    },

    handleDeleteOption() {
      const options = cloneObject(this.item.diagnostico_produto_item_opcoes);
      const index = this.currentOptionIndex;

      options.splice(index, 1);

      this.$set(this.item, 'diagnostico_produto_item_opcoes', options);

      if (this.item.diagnostico_produto_item_opcoes[index - 1]) {
        this.currentOptionIndex = index - 1;
      } else if (this.item.diagnostico_produto_item_opcoes[index]) {
        this.currentOptionIndex = index;
      } else {
        this.currentOptionIndex = null;
      }
    },

    renderDataGraph() {
      this.columnChart.series = this.series(
        this.item.necessidades,
        this.item.valor_atual,
        this.item.valor_necessario,
        this.totalSugeridos
      );
    },

    saveQuotationRequest() {
      return new Promise((resolve, reject) => {
        const data = {
          diagnostico_produto_item_opcoes: cloneObject(
            this.item.diagnostico_produto_item_opcoes
          )
        };

        updateDiagnosisProductItemOpcoes(
          this.item.diagnostico_produto_item_id,
          data
        )
          .then((res) => {
            this.item.aguardando_resposta = true;
            this.$set(
              this.item,
              'diagnostico_produto_item_opcoes',
              res.data.result.data.diagnostico_produto_item_opcoes
            );
            this.editMode = false;

            resolve(res.data.result.data);
          })
          .catch((error) => {
            let errorMessage = 'Houve um erro ao salvar as opções';

            if (error.response) {
              if (
                error.response.data.statusCode &&
                error.response.data.statusCode !== 200
              ) {
                errorMessage = error.response.data.statusMessage;
              }

              if (
                error.response.data.message &&
                !error.response.data.message.diagnostico_produto_item_opcoes
              ) {
                errorMessage = 'Todos os valores são obrigatórios';
              }
            }

            alert(errorMessage);
            reject(error);
          });
      });
    },

    handleEditQuotation() {
      this.editMode = true;
      this.optionsBackup = cloneObject(
        this.item.diagnostico_produto_item_opcoes
      );
    },

    handleCancel(item) {
      this.editMode = false;
      item.diagnostico_produto_item_opcoes = [];
    },

    handleSaveQuotation() {
      this.saveQuotationRequest();
    }
  },

  computed: {
    totalSugeridos() {
      if (this.item.tipo_tabela !== 1) return null;

      return this.item.diagnostico_produto_item_tabela_itens.reduce(
        (total, item) => total + item.sugeridos,
        0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.graph-column {
  overflow-x: auto;
}
</style>
