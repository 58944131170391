var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-finance-indicators'),expression:"'accordion-finance-indicators'"}],staticClass:"text-left rounded-0",staticStyle:{"font-size":"1.25rem"},attrs:{"block":"","variant":"secondary"}},[_c('i',{staticClass:"bx bxs-traffic"}),_vm._v(" INDICADORES FINANCEIROS "),_c('i',{staticClass:"bx bxs-chevron-up when-closed float-right mt-1"}),_c('i',{staticClass:"bx bxs-chevron-down when-opened float-right mt-1"})])],1),(_vm.diagnosis)?_c('b-card-body',{staticStyle:{"background-color":"var(--background-light-grey)"}},[_c('b-collapse',{attrs:{"id":"accordion-finance-indicators","role":"tabpanel"}},[_c('b-row',[_c('b-col',{staticClass:"indicator-finances-container",attrs:{"md":"12","id":"indicator-finances-container"}},_vm._l((_vm.diagnosis.indicadores_financeiros),function(indicator,index){return _c('div',{key:index,staticClass:"indicator-finance-card",style:({ border: ("1px solid " + (indicator.color)) })},[_c('div',{staticClass:"content-indicator"},[_c('p',[_vm._v(_vm._s(indicator.name))]),_c('div',{staticClass:"font-weight-bold"},[(indicator.type === 'money')?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(indicator.value))+" ")]):_vm._e(),(indicator.type === 'money_percentage')?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(indicator.value))+" = "+_vm._s(_vm._f("decimal")(indicator.percentage))+"% ")]):_vm._e(),(indicator.type === 'months')?_c('span',[_vm._v(" "+_vm._s(indicator.value)+" meses ")]):_vm._e()])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                !indicator.tooltip
                  ? undefined
                  : {
                      title: indicator.tooltip,
                      html: true,
                      placement: 'bottom'
                    }
              ),expression:"\n                !indicator.tooltip\n                  ? undefined\n                  : {\n                      title: indicator.tooltip,\n                      html: true,\n                      placement: 'bottom'\n                    }\n              ",modifiers:{"hover":true}}],staticClass:"circle-indicator",style:({ backgroundColor: indicator.color })})])}),0),_c('i',{staticClass:"fas fa-chevron-right style-arrow-right",on:{"click":function () { return _vm.handleScrollxAxis('right'); }}}),(_vm.scrollsize)?_c('i',{staticClass:"fas fa-chevron-left style-arrow-left",on:{"click":function () { return _vm.handleScrollxAxis('left'); }}}):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }